import React from "react"
import "./../index.css"

const Hero = () => {
  return (
    <header className="hero">
      <div className="hero-content">
        <h1 className="hero-title">Turn Your Photos Into Masterpieces</h1>
        <p className="hero-subtitle">
          Transform any photo into a stunning painting with our easy-to-use app.
        </p>
        <a
          href="https://apps.apple.com/us/app/idXXXXXXXXX"
          className="cta-button"
        >
          Download the App
        </a>
      </div>
      <div className="hero-shape"></div>
    </header>
  )
}

export default Hero

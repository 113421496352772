import React from "react"
import "./App.css"
import Hero from "./components/Hero.js"
import Feature from "./components/Feature"
import CTA from "./components/CTA"
import Footer from "./components/Footer"

function App() {
  return (
    <div className="App">
      <Hero />
      <div className="features">
        <Feature
          title="Guided Painting Experience"
          text="Follow intuitive steps to paint your photo, ensuring a satisfying and fun experience."
          image="feature1.png"
          reverse={false}
        />
        <Feature
          title="Professional Tools"
          text="Access a variety of brushes, colors, and textures to create the perfect piece of art."
          image="feature2.png"
          reverse={true}
        />
        <Feature
          title="Share and Inspire"
          text="Share your artwork with a community of artists and get inspired by others."
          image="feature3.png"
          reverse={false}
        />
      </div>
      <CTA />
      <Footer />
    </div>
  )
}

export default App

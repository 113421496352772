import React from "react"
import "./../index.css"

const Feature = ({ title, text, image, reverse }) => {
  console.log("image: ", image)
  return (
    <div className={`feature ${reverse ? "reverse" : ""}`}>
      <img src={image} alt={title} className="feature-image" />
      <div className="feature-text">
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </div>
  )
}

export default Feature

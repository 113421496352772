import React from "react"
import "./../index.css"

const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; 2024 Your Company Name. All rights reserved.</p>
      <a href="/privacy-policy">Privacy Policy</a>
      <a href="/terms-of-service">Terms of Service</a>
    </footer>
  )
}

export default Footer

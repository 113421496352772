import React from "react"
import "./../index.css"

const CTA = () => {
  return (
    <section className="cta">
      <h2>Get Started Today!</h2>
      <p>
        Download the app now and start turning your photos into beautiful
        paintings.
      </p>
      <a
        href="https://apps.apple.com/us/app/idXXXXXXXXX"
        className="cta-button"
      >
        Download on the App Store
      </a>
    </section>
  )
}

export default CTA
